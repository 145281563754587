@font-face{
  font-family: 'MyFont';
  src: url('./assets/font/selawkl.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .webgl{
  height: 100vh;
}

*{
  margin: 0;
}

canvas { 
  outline: none; 
  border: none; 
}


@media only screen and (orientation:portrait){
  #generic{
    visibility: visible;
    width: 100vw;
    height: 77.5vh;
    /* background-color: red; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center;
    font: 'MyFont';
    color: white;
    /* font- */
  }

  #generic div{
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 10px;
  }

  #canvas-area{
    visibility: hidden !important;
    display: none;

  }

  #user{
    visibility: hidden !important;
    display: none;
  }

  #rotate{
    height: 10vh;
    width: 10vw;
    color: rgb(86,101, 115);
    /* background-color: rgba(0, 0, 0, 0.685); */

  }
}

@media only screen and (orientation:landscape){
  #generic{
    visibility: hidden;
    display: none;
    
  }
}