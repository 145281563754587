#header{
  height: 15vh;
  width: 100vw;
  text-align: center;
  font-family: 'MyFont';
  background-color: #f7f6fb;

  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 2.5vw;

}

#name1{
  width: 25%;
  text-align: right;
}

#logo{
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

#name2{
  width: 25%;
  text-align: left;
}

#p-button, #span{
  width: 10vw;
  position: relative;
  display: flex;
  justify-content: center;
}

#logoHeader{
  height: 100%;
}



#patreonButton{
  position: relative;

  
  padding: 7.5px 7.5px;

  cursor: pointer;
  border: solid thin;
  border-radius: 10px;
  background-color: #ffffff;
  color: black;
  font-family: 'MyFont';
  text-decoration: none;
  font-size: 0.9vw;
}

#patreonButton:hover{
  filter: invert(100%);
}
