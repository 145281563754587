#canvas-area{
  /* background-color: #ff0000; */
  height: 77.5%;
}

canvas{
  height: 100%;
  width: 100%;
}

#test{
  /* background-color: red; */
  height: 77.5%;
}

#sceneName{
  position: absolute;
  top: 15%;
  left: 45%;
  width: 10%;
  background: white;
  opacity: 50%;
  text-align: center;
}
