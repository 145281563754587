#user{
  position: absolute;
  height: 77.5vh;
  width: 10vw;
  /* background-color: rgb(255, 8, 0); */
}

div#back{
  padding: 1.5vh 1vw 1.5vh 1vw;
  position: absolute;
  top: 0;
}

button#back{
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: solid thin;
  color: white;
  font: 'MyFont';
  padding: 1vh 2vw;
}

button#back:hover{
  filter: invert(100%);
  cursor: pointer;
}

#info-cont{
  position: absolute;
  top: 10%;
  left: 25vw;
  overflow: hidden;
  
  border-radius: 10%;
  border: solid;
  height: 80%;
  transition: 1.0s;
  background-color: rgba(0, 0, 0, 0.75);
  font-family: 'MyFont';
  font-size: 2vw;
  color: white;
  
  padding: 10px;
  /* margin: 0px; */
  
  /* width: 50vw; */

  display: flex;
  flex-flow: wrap column;
  justify-content:center;
  
}


#b-info{
  position: absolute;
  bottom: 6vh;
  left: 0.5vw;
}

#info-button{
  background-color: rgb(88, 82, 82);
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border: 0;
  border-radius: 15%;
  padding: 1px;
  height: 5vh;
  width: 2.5vw;
}

#b-audio{
  position: absolute;
  bottom: 0vh;
  left: 0.5vw;
}

#audio{
  background-color: rgb(88, 82, 82);
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border: 0;
  border-radius: 15%;
  padding: 1px;
  height: 5vh;
  width: 2.5vw;

  /* background-color: green; */
}

.icon{
  /* transform: scale(1.5); */
  /* stroke-width: 2px; */
  height: 100%;
  width: 100%;
  /* background-color: red; */
}