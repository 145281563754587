#footer{
  background-color: #f7f6fb;
  /* background: blue; */
  width: 100%;
  height: 7.5%;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.shortcut{
  height: 100%;
  /* background: green; */
  display: flex;
  align-items: center;
}

.link-sm{
  height: 80%;
  cursor: pointer;
  text-decoration: none;
  /* background: yellow; */

}

.image-sm{
  height: 100%;
  display: block;
  /* background: purple; */
}

.shortcut:hover{
  /* cursor: pointer; */
  filter: invert(100);
}

#spotifyOpener.on{
  filter: invert(100);
}

/* #spotifyOpener.on:hover{
  filter: invert(100);
} */

#spotifyOpener.off{
  filter: invert(0);
}

/* #spotifyOpener.off:hover{
  filter: invert(0);
} */

#spoCont{
  background: none!important;
  border: none!important;
  padding: 0!important;
  height: 80%;
  cursor: pointer;
  text-decoration: none;
  outline:none;
}