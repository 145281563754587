#spotifyMenu{
  position: fixed;
  bottom: 7.5vh; /* Stay at the top */
  right: 0;
  overflow-x: hidden;

  border-radius: 10px;
  width: 0px;
  height: 0px;
  max-height: 40vh;
  transition: 0.5s;
  background-color: #f7f6fb;
}

#flexContainer{
  display: flex;
  flex-flow: wrap row;
  justify-content: space-around;
}

@media screen and (min-width: 601px) {
  .s-artist{
    width: 30%;
  }
  .titleArtist{
    font-size: 2vw;
  }
}

@media screen and (max-width: 601px) {
  .s-artist{
    width: 52.5%;
  }
  .titleArtist{
    font-size: 3vw;
  }
}

.s-image{
  width: 100%;
}

.titleArtist{
  text-align: center;
  font-family: 'MyFont';
  margin-bottom: 10px;
}

.s-link{
  width: 50%;
}
