#load-overlay{
  /* #Position of overlay */
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* #Background of overlay */
  /* todo: reference to new image */
  /* ? background-image: url('../../assets/PU_Splashscreen.png'); */
  background-image: url('../../assets/PU_Splashscreen_Empty.png');
  /* ? background propietes temporal */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; /* Resize the background image to cover the entire container */
  
  font-family: 'MyFont';
  color: white;

  /* #Contents of the overlay */
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
}

#load-logo{
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

#load-logo img{
  width: 40%;
}



#load-message p{
  text-align: center;
  font-size: 16pt;
}

.overlay-content{
  /* background-color: rgba(45, 51, 45, 0.11); */
  text-align: center;
}

.loading-button{
  width: 20vh;
  padding: 5px 12px;
  /* cursor: pointer; */
  border: solid thin;
  border-radius: 15px;
  background-color: rgba(0,0,0,0.5);
  font-family: 'MyFont';
  font-size: 3vh;
  color: white;
  /* display: none; */

  /* ? temporals */
  /* position:absolute;
  bottom: 10%;
  left: 45%; */
}

.enter-button{
  /* ? temporals */
  /* width: 20vw; */
  /* background-color: red; */
  /* position:absolute; */
  /* bottom: 10%; */
  /* left: 45%; */
}

.enter-button:hover{
  filter: invert(100%);
  cursor: pointer;
}

.loading{
  opacity: 0;
  animation: fadeinout 5s infinite;
}

@keyframes fadeinout {
  /* 0%,100% { opacity: 0 } */
  50% { opacity: 1 }
}
